<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }"
       style="background-color: #000000">

    <!-- map container begin -->
    <BRow>
      <BCol ref="mapContainer" id="mapContainer" class="m-0 p-0" :style="mapStyle">

        <div  v-show="showLayer" class="map-overlay" @click.self="showLayer=false">
          <div class="map-layer-content"
               @mousedown="startDrag"
               :style="{ top: `${layerTop}px`, left: `${layerLeft}px` }">

            <BRow>
              <BCol ref="tankContainer"
                    :style="tankContainerStyle">
              </BCol>
              <BCol>

                <TankLineChart v-if="tank && graphData" style="min-height: 300px;"
                               :tank="tank"
                               :tankData="graphData"
                               :oilColor="oilColors[tank.oilCode]"
                               :key="chartKey"
                />

              </BCol>
            </BRow>

            <LayerPanel :tank="tank"
                        :pk="pk"
                        :key="layerKey"
            />

            <BButton class="align-content-center small"
                     variant="info" size="sm" @click="closeLayer"
                     style="top:10px;right:10px;position:absolute">닫 기</BButton>

          </div>
        </div>


      </BCol>
    </BRow>

    <!-- map container end -->

    <CCard class="m-0 p-0 mt-1">
    <CCardHeader>
      <CIcon name="cil-map"/>
      3D 컨트롤
      <BButton size="sm" variant="info" @click="toggleSize">{{ toggleMapSize ? '작게보기' : '전체화면' }}</BButton>
      <BButton size="sm" variant="primary" @click="moveCamToStartPoint" class="ml-1" >초기위치로 이동</BButton>

      <div class="card-header-actions">
        <BBadge variant="info">selected</BBadge> <small class="text-muted"> {{ selectedPoint }} {{ camPoint }}</small>
        <BBadge variant="info">location</BBadge> <small class="text-muted"> {{ screenPoint }}</small>
      </div>
    </CCardHeader>
    <CCardBody>

      <CRow>
        <CCol v-if="tanks.length">
          <BButton size="sm" v-for="t in tanks"
                   :key="t.tid"
                   :variant="tankUseColor[t.tankUse]"
                   class="mr-1 mb-1"
                   v-show="t.display"
                   @click="popupLayer(t.tid);moveCamToTank(t.tid);">
            {{ t.name }} ({{t.tankCode}})
          </BButton>

        </CCol>
      </CRow>


    </CCardBody>
  </CCard>

  </div>
</template>

<style>
.map-parent-div {
  position: relative; /* 부모 div를 상대 위치 기준으로 설정 */
  width: 80%;
  height: 400px;
  margin: 50px auto;
  border: 2px solid #2c3e50;
  text-align: center;
}

.map-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* 배경 클릭을 허용하지 않음 */

}

.map-layer-content {
  background: rgba(100, 255, 255, 0.20); /* 반투명 흰색 배경 */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: left;
  pointer-events: auto; /* 레이어 내용은 클릭 가능 */
  position: absolute;
  width: 900px; /* 너비 900px */
  height: 660px; /* 높이 500px */
  cursor: move; /* 드래그 커서 */
}

</style>

<script>

import store from "@/store";
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import {MapControls} from 'three/examples/jsm/controls/MapControls'
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'
// import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
// import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import {gsap} from 'gsap';
import {
  apiCall,
  getHexColor,
  modalWarn,
  sleep,
  speech,
  startFullScreen,
  cancelFullScreen,
  ltr2gal, cloneVar
} from "@/common/utils";
import {EqCodeMap, eventColorMap, IoStsVariant} from "@/common/constants";
import LayerPanel from "@/views/components/LayerPanel.vue";
import TankLineChart from "@/views/components/charts/TankLineChart.vue";


export default {
  name: 'TankLorryMonitor',
  components: {TankLineChart, LayerPanel},
  data() {
    return {
      ltr2gal,
      eventColorMap,
      IoStsVariant,
      oilColors: this.$store.state.codeMaps['OIL_COLOR'],
      tank: null,

      message: '',
      showLayer: false,
      layerTop: 40, // 초기 top 위치
      layerLeft: 40, // 초기 left 위치
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,

      socket: null,

      tankContainer: null,
      tankContainerStyle: {
        width: "450px",
        height: "300px"
      },
      tankCamera: null,
      tankRenderer: null,
      tankScene: null,
      tankControls: null,
      layerTankObj: null,
      LayerVolObj: null,
      fighterObjs: {},
      layerKey: 0, // for progress bar refresh

      // layer vars
      pk: null,

      mapStyle: {height: '710px'},
      toggleMapSize: false,
      renderer: null,
      scene: null,
      camera: null,
      orbit: null,
      controls: null,
      raycaster: null,
      font: null,
      mouse: new THREE.Vector2(),

      tankObjs: {},
      labelObjs: {},
      volObjs: {},

      tankLorryGltf: null,
      f35Gltf: null,
      f16Gltf: null,

      tanks: [],
      tankMap: {},
      pkMap: {},

      wRatio: 0.0018,
      hRatio: 0.005,
      // yRatio: 0.01, // horizontal cylinder 에만 적용

      tankUseColor:{
        '1': 'success', // 대량저장
        '2': 'secondary', // Drain
        '3': 'danger', // truck
        '4': 'secondary', // 송유관
        '5': 'dark', // 드럼
        '6': 'warning', // 주유소
        '7': 'info', // 난방
        '': 'dark'
      },
      selectedPoint: null,
      camPoint: null,
      screenPoint: {x: 0, y: 0, z: 0},

      tankTypes: store.state.codeMaps['TANK_TYPE'],
      tankUses: store.state.codeMaps['TANK_USE'],
      tankShapes: store.state.codeMaps['TANK_SHAPE'],
      center: {lat: 36.707988, lng: 127.500557},
      markers: [],
      infoContent: '',
      infoLink: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      // optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      dmStyle: {
        "background": "rgba(0, 255, 255, 0.3)", /* 배경을 투명하게 설정 */
        "border": "none",
        "border-radius": "10px"
      },
      graphData: null,
      chartKey: 0,
    }
  },
  async created() {
    console.log("\n\n\n\n\n\n\n\n\n\n\n\n\n---------tank-lorry monitor created--------------------------\n")
    this.setSocketConnection();
    await sleep(1000);
    window.addEventListener("resize", this.onPageResizeHandler);

  },
  computed: {

  },

  async mounted() {
    console.log("\n\n\n\n\n\n\n\n\n\n\n\n\n---------tank-lorry monitor mounted--------------------------\n")
    await this.getLatestTanks();

    this.renderer = new THREE.WebGLRenderer({antialias: true});
    this.container = this.$refs['mapContainer'];
    this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    this.container.appendChild(this.renderer.domElement);
    // this.renderer.setClearColor(0x202020);
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color("skyblue");
    // this.renderer.domElement.addEventListener('click', this.onDocumentMouseClick, false);
    await this.loadObjects();

    this.setCamera();
    this.loadMap();
    this.addLight();

    this.raycaster = new THREE.Raycaster();
    this.raycaster.near = 0.1;
    this.raycaster.far = 5000;

    /** helper for debug */
      // const gridHelper = new THREE.GridHelper(6700,100);
      // scene.add( gridHelper );

    const axesHelper = new THREE.AxesHelper(300);
    axesHelper.position.set(0, 0, 0);
    this.scene.add(axesHelper);

    await this.initLabels();
    await this.initTanks();
    await this.initLayer();

    this.renderer.setAnimationLoop(this.animate);
    this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    this.moveCamToStartPoint();

    window.addEventListener('click', this.onMouseClick, false);
    // tank container init
  },

  methods: {
    async getLatestTanks(){
      try {
        const {result} = await apiCall('get', `/api/tank/lorry/latest`);
        // console.log("\n\n\n----------getLatestTanks()---------rs----", result);
        if(result){
          this.tanks = result.tanks;
          this.pkMap = result.pkMap;
          this.tanks.map(t=>{this.tankMap[t.tid]=t});
          // console.warn("\n\n\n----------getLatestTanks()--------- tankMap, tanks---", this.tankMap, this.tanks);
        }
      }catch(err){
        console.error(err);
      }
    },

    async getTankChart(){
      try{
        if(!this.tank) return;
        const {tid} = this.tank;
        const {result} = await apiCall('get', `/api/graph/tank/24?tid=${tid}`);
        this.graphData = result.tankHist[tid];
        console.debug( this.graphData);
        this.chartKey++;
      }catch(err){
        console.error(err);
      }
    },

    async initLayer() {

      const tankContainer = this.$refs['tankContainer'];
      const tankRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      tankRenderer.setSize(450, 300);
      tankContainer.appendChild(tankRenderer.domElement);

      const tankScene = new THREE.Scene();
      const tankCamera = new THREE.PerspectiveCamera(75, 450/300, 0.1, 1000);
      const light = new THREE.DirectionalLight(0xffffff, 1);
      // const light = new THREE.AmbientLight(0xFFFFFF);
      light.position.set(5, 100, 7.5);
      tankScene.add(light);

      const axesHelper = new THREE.AxesHelper(10);
      axesHelper.position.set(0,0,0);
      tankScene.add( axesHelper );
      tankCamera.position.set(500,10, 500);

      // OrbitControls
      const controls = new OrbitControls( tankCamera, tankRenderer.domElement);
      controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation is enabled
      controls.dampingFactor = 0.25;
      controls.screenSpacePanning = false;
      controls.maxPolarAngle = Math.PI / 2;
      tankCamera.lookAt(0, 0, 0);

      this.tankScene = tankScene;
      this.tankRenderer = tankRenderer;
      this.tankCamera = tankCamera;

      // let angle = 0;
      // const radius = 90;
      function tankAnimate() {
        // angle += 0.02;
        // tankCamera.position.x = radius * Math.sin(angle);
        // tankCamera.position.z = radius * Math.cos(angle);
        // tankCamera.lookAt(0,0,0);
        requestAnimationFrame(tankAnimate);
        controls.update();

        tankRenderer.render(tankScene, tankCamera);
      }
      tankAnimate();
    },

    /**
     * tank container draw tank at tank container
     * @param t tank
     * @param pk
     * @returns {Promise<void>}
     */
    getLayerScale(){},

    clearObject(object, scene) {
        if (object.geometry) {
          object.geometry.dispose();
        }
        if (object.material) {
          if (Array.isArray(object.material)) {
            object.material.forEach(material => material.dispose());
          } else {
            object.material.dispose();
          }
        }
        scene.remove(object);
     },

    async drawTankOnLayer(t, pk=null){
      // const {tid} = t;
      console.warn( "------------draw-tank-on-layer------------");
      console.warn( "tank  shape , width, height =>", t.tankShape, t.tankWidth, t.tankHeight );
      if(this.layerTankObj) {
        console.warn( "------------draw-tank-on-layer --- layerTankObj exists------------" );
        this.tankScene.remove(this.layerTankObj);
        this.layerTankObj = null;
      }

      const camera = this.tankCamera;
      const tankObj = this.tankLorryGltf.clone();
      // TankLorry 는 아래로 -20 이동
      tankObj.position.set(0, -20 ,0);
      tankObj.updateMatrix();
      this.tankScene.add( tankObj );
      this.drawTankLorryVolOnLayer(t, pk);

      this.layerTankObj = tankObj;
      console.warn( "------------draw-tank-on-layer --- add tank scene done!");

      let scale = this.getScale(t);
      // 스케일 확대
      gsap.to(tankObj.scale, {
        duration: 2,
        repeat: 0,
        x: scale,
        y: scale,
        z: scale,
        yoyo: false,
        ease: "sine.inOut",
      });

      // 카메라 회전
      let angle = 0;
      const radius = 90;
      gsap.to(camera.position, {
        duration: 30,
        repeat: -1, // 무한 반복
        yoyo: false, // 역방향 애니메이션
        onUpdate: ()=> {
          angle += 0.02;
          camera.position.x = radius * Math.sin(angle);
          camera.position.z = radius * Math.cos(angle);
        },
      });
    },

    getScale(t){
      let scale = 1;
      // let tankY = 0;
      // if( t.tankShape==='2') tankY = h * this.hRatio * 0.5;
      const h = t.tankHeight
      if( h < 3000 ) scale = 3;
      else if ( h < 5000 ) scale = 2;
      else scale = 1.1;

      if(t.tankShape==='4') { // 탱크로리
        scale = 0.14; // 0.09 --> 0.14 = 155%
      }
      return scale
    },

    drawTankLorryVolOnLayer(t, pk=null){
      console.log(  '------------draw-tank-lorry-vol-on-layer-------------' );
      const {tid} = t;
      // let scale = this.getScale(t);
      if( this.layerVolObj?.oil ){
        console.log(  '------------draw-tank-lorry-vol-on-layer------ delete layerVolObj-------------' );
        this.tankScene.remove( this.layerVolObj.oil);
        if(this.layerVolObj.wtr) this.tankScene.remove( this.layerVolObj.wtr );
        this.tankScene.remove()
      }

      if(pk){
        this.pk = pk;
        this.tank.volPercent = pk.tvp;
        this.layerKey++;
      }else{
        return;
      }

      const volObj = this.volObjs[tid];
      if( volObj && volObj.mes && volObj.mes.oil ){
        console.log( '------------draw-tank-lorry-vol-on-layer---volume exist ')
      }else{
        console.log( '------------draw-tank-lorry-vol-on-layer---volume not exist ---- skip')
        return false;
      }
      // console.warn('draw-tank-lorry-vol-on-layer --- volObj=', volObj)
      const oilVolObj = volObj.mes.oil.clone();
      const {oilY} = volObj;
      oilVolObj.position.set(0, oilY + 6, -29);
      // oilVolObj.updateMatrix();
      // wtrVolObj.updateMatrix();
      console.log( 'draw-tank-lorry-vol-on-layer --- scale = ', JSON.stringify(oilVolObj.scale) )

      gsap.to(oilVolObj.scale, {
        duration: 2.5,
        repeat: 0,
        x: 1.7,
        y: 1.7,
        z: 1.7,
        yoyo: false,
        ease: "sine.inOut",
      });
      this.layerVolObj = {
        oil: oilVolObj,
        wtr: null
      }
      this.tankScene.add(oilVolObj);

    },

    async toggleSize() {
      this.toggleMapSize = !this.toggleMapSize;
      if (this.toggleMapSize === true) {
        startFullScreen();
        await sleep(500);
        this.$store.state.sidebarMinimize = true;
        this.mapStyle = {height: `${window.innerHeight - 350}px`}
        // document.getElementById('mapContainer').style.height= '1000px';
        // this.$refs.mapContainer[0].style = { height: '1000px' }
      } else {
        cancelFullScreen();
        await sleep(500);
        this.$store.state.sidebarMinimize = false;
        this.mapStyle = {height: '710px'}
        // this.$refs['mapContainer'].style.height = '800px';
      }
      await sleep(500);
      this.onPageResizeHandler();

    },

    animate() {
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    },

    setCamera() {
      this.camera = new THREE.PerspectiveCamera(
        45,
        this.container.offsetWidth / this.container.offsetHeight,
        0.1,
        18000
      );
      // this.controls = new OrbitControls( this.camera, this.renderer.domElement );
      this.controls = new MapControls(this.camera, this.renderer.domElement)
      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.05;
      this.controls.enableZoom = true;
      // this.camera.position.set( 113,800,150 ); // x, y(높이), z
      this.camera.position.set(0, 100, 0); // x, y(높이), z
      this.camera.lookAt(0, 0, 0);

      // this.orbit.update();
    },

    async popupLayer( tid ){
      this.tank = this.tankMap[tid];
      this.tank['volPercent'] = 0;

      this.volUnit = this.tank.unitOfVol?this.tank.unitOfVol:'l';
      this.lenUnit = this.tank.unitOfLen?this.tank.unitOfLen:'mm';
      this.tmpUnit = this.tank.unitOfTmp?this.tank.unitOfTmp:'c';

      let pk;
      try {
        const rs = await apiCall('get', `/api/inventory/latest/${tid}`);
        if(rs.code===200 && rs.result) {
          pk = rs.result;
          this.pk = pk;
          this.tank.volPercent = pk.tvp;
          this.layerKey++;
        }
      } catch (err){
        console.error(err);
      }
      await this.drawTankOnLayer(this.tank, pk);
      this.showLayer = true;
      await this.getTankChart();
    },

    closeLayer(){
      this.showLayer = false;
      this.tank = null;
      this.selectedPoint = null;
      this.camPoint = null;

      if(this.layerTankObj) {
        this.tankScene.remove(this.layerTankObj);
        this.layerTankObj = null;
      }
    },

    async moveCamToTank(tid, duration=0.7) {
      const p = this.tankMap[tid].position;
      if (!p || p.x === 0) {
        alert('[위치정보 없음] 이동할 수 없습니다.');
        return;
      }

      this.tank = this.tankMap[tid];
      let cmp; // camera position

      this.selectedPoint = {x:p.x, y:p.y, z:p.z};
      if ( p.cx && p.cz ) {
        cmp = {
          x: p.cx,
          y: p.cy,
          z: p.cz
        }
      } else {
        cmp = {
          x: (p.x < 0) ? p.x + p.ax - 200 : p.x + p.ax + 200,
          y: p.y + p.ay + 100,
          z: (p.z < 0) ? p.z + p.az - 250 : p.z + p.az + 250
        }
        // cmp = {
        //   x: p.x + p.ax - 200,
        //   y: p.y + p.ay + 100,
        //   z: p.z + p.az - 250
        // }
      }

      this.camPoint = {x:cmp.x, y:cmp.y, z:cmp.z};

      console.debug('moveCamToTank--->', tid, JSON.stringify(p), 'fixed moveTo=', JSON.stringify(cmp));
      const label = this.labelObjs[tid];
      const color = this.tankMap[tid].color;
      const camera = this.camera;

      gsap.to(camera.position, {
        duration: duration,
        repeat: 0,
        x: cmp.x,
        y: cmp.y,
        z: cmp.z,
        onUpdate: () => {
          camera.lookAt(new THREE.Vector3(p.x, p.y, p.z));
        },
      });

      /*
      gsap.to(camera.position, {
        duration: 2,
        repeat: 0,
        x: p.cx + -40 * Math.sin(Math.PI),
        y: moveTo.y * Math.sin(Math.PI),
        z: p.cz + -40 * Math.cos(Math.PI),
        ease: "power1.inOut",
        modifiers: {
          x: (x) => p.cx + -40 * Math.sin(performance.now() / 1000),
          y: (y) => moveTo.y + -40 * Math.cos(performance.now() / 1000),
          z: (z) => p.cz + -40 * Math.cos(performance.now() / 1000)
        },
        onUpdate: () => {
          camera.lookAt(p.x, p.y, p.z);
        },
        onComplete: () => {
        }
      });
      */

      await this.lightingObject(label, getHexColor(color));

      /*
            gsap.to( camera.position, {
              x: moveTo.x,
              y: moveTo.y,
              z: moveTo.z,
              duration: 2, // 애니메이션 시간 (초)
              modifiers: {
                // x: (x) => x * Math.sin(performance.now() / 1000),
                // z: (z) => z * Math.cos(performance.now() / 1000)
              },
              onUpdate: () => {
                //camera.lookAt( new THREE.Vector3(p.x+p.ax, p.y, p.z + p.az) ); // 카메라가 항상 중앙을 바라보도록 하려면 (0,0,0)
                camera.lookAt( tankMesh.position );
                // this.camera.lookAt( new THREE.Vector3( 0, 0, 0 )); // 카메라가 항상 중앙을 바라보도록 하려면 (0,0,0)
              },
              onComplete: ()=>{
              }
            });
        */
      // console.debug('moveCamToTank() --->', moveTo );
    },

    /**
     *
     * @param tid
     * @param pk
     * @returns {Promise<void>}
     */
    async traceTank(tid, pk) {

      const tank = this.tank;
      const camera = this.camera;
      const p = tank.position;

      if (!p || p.x === 0) {
        alert('[위치정보 없음] 이동할 수 없습니다.');
        return;
      }
      this.tank['volPercent'] = 0;

      let cmp; // camera position

      this.selectedPoint = {x:p.x, y:p.y, z:p.z};

      if ( p.cx && p.cz ) {
        cmp = {
          x: p.cx,
          y: p.cy,
          z: p.cz
        }
      } else {

        cmp = {
          x: (p.x < 0) ? p.x + p.ax - 200 : p.x + p.ax + 200,
          y: p.y + p.ay + 100,
          z: (p.z < 0) ? p.z + p.az - 250 : p.z + p.az + 250
        }
        // cmp = {
        //   x: p.x + p.ax - 200,
        //   y: p.y + p.ay + 100,
        //   z: p.z + p.az - 250
        // }
      }

      this.camPoint = {x:cmp.x, y:cmp.y, z:cmp.z};

      console.debug('traceTank--->', tid, JSON.stringify(p), 'fixed moveTo=', JSON.stringify(cmp));
      const label = this.labelObjs[tid];
      const color = this.tankMap[tid].color;

      gsap.to(camera.position, {
        duration: 2.5,
        repeat: 0,
        x: cmp.x,
        y: cmp.y,
        z: cmp.z,
        onUpdate: () => {
          camera.lookAt(p.x, p.y, p.z);
        },
      });
      await sleep(1000);

      /*
      gsap.to(camera.position, {
        duration: 2,
        repeat: 0,
        x: p.cx + -40 * Math.sin(Math.PI),
        y: moveTo.y * Math.sin(Math.PI),
        z: p.cz + -40 * Math.cos(Math.PI),
        ease: "power1.inOut",
        modifiers: {
          x: (x) => p.cx + -40 * Math.sin(performance.now() / 1000),
          y: (y) => moveTo.y + -40 * Math.cos(performance.now() / 1000),
          z: (z) => p.cz + -40 * Math.cos(performance.now() / 1000)
        },
        onUpdate: () => {
          camera.lookAt(p.x, p.y, p.z);
        },
        onComplete: () => {
        }
      });
      */

      await this.lightingObject(label, getHexColor(color));

      /*
            gsap.to( camera.position, {
              x: moveTo.x,
              y: moveTo.y,
              z: moveTo.z,
              duration: 2, // 애니메이션 시간 (초)
              modifiers: {
                // x: (x) => x * Math.sin(performance.now() / 1000),
                // z: (z) => z * Math.cos(performance.now() / 1000)
              },
              onUpdate: () => {
                //camera.lookAt( new THREE.Vector3(p.x+p.ax, p.y, p.z + p.az) ); // 카메라가 항상 중앙을 바라보도록 하려면 (0,0,0)
                camera.lookAt( tankMesh.position );
                // this.camera.lookAt( new THREE.Vector3( 0, 0, 0 )); // 카메라가 항상 중앙을 바라보도록 하려면 (0,0,0)
              },
              onComplete: ()=>{
              }
            });
        */


      // console.debug('moveCamToTank() --->', moveTo );

    },
    async getLatestPacket(tid){
      try {
        const {result} = await apiCall('get', `/api/inventory/latest/${tid}`);
        return result;
      } catch (err){
        console.error(err);
      }

    },

    moveCamToStartPoint() {
      this.closeLayer();
      gsap.to(this.camera.position, {
        x: -1000, // 좌측
        y: 900, // 높이
        z: 1200, // 아래
        duration: 3, // 애니메이션 시간 (초)
        onUpdate: () => {
          this.camera.lookAt(0, 0, 0); // 카메라가 항상 중앙을 바라보도록
        }
      });
    },

    async lightingObject(obj, color, cnt = 9) {
      for (let i = 0; i < cnt; i++) {
        obj.material.color.set(0x000000);
        await sleep(200);
        obj.material.color.set(color);
        await sleep(200);
      }
    },

    async blinkObject(obj, cnt = 9) {
      for (let i = 0; i < cnt; i++) {
        obj.visible = false;
        await sleep(200);
        obj.visible = true;
        await sleep(200);
      }
    },

    loadMap() {
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load('/map-data/circle-map-v2.jpg');
      // const geometry = new THREE.PlaneGeometry(10120, 10120); // Adjust the size as needed
      const geometry = new THREE.CircleGeometry(10120 / 2, 180); // Adjust the size as needed
      // const backgroundColor = new THREE.Color(0xFF0000);

      /*
            const material = new THREE.MeshBasicMaterial({
                // color: new THREE.Color(0xFF0000),
                map: texture,
                alphaMap: texture,
                transparent: false,
                side: THREE.DoubleSide
              }
            );
      */
      const material = new THREE.ShaderMaterial({
        uniforms: {
          texture1: {type: 't', value: texture},
          darkness: {type: 'f', value: 1.1} // 어둡게 만들기 위한 값 (0.0 ~ 1.0)
        },
        vertexShader: `
            varying vec2 vUv;
            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
        fragmentShader: `
            uniform sampler2D texture1;
            uniform float darkness;
            varying vec2 vUv;
            void main() {
                vec4 color = texture2D(texture1, vUv);
                color.rgb *= darkness; // 텍스처의 색상을 어둡게 만듭니다.
                gl_FragColor = color;
            }
        `,
        transparent: true
      });

      const mapPlane = new THREE.Mesh(geometry, material);
      // this.mapPlane.rotation.z = Math.PI / 2;
      mapPlane.rotation.x = Math.PI / -2;
      // this.mapPlane.position.y = -5;
      this.scene.add(mapPlane);
    },

    addLight() {
      // const ambientLight = new THREE.AmbientLight(0x404040);
      const ambientLight = new THREE.DirectionalLight(0xffffff, 1);
      ambientLight.position.set(1000, 600, -2000);
      this.scene.add(ambientLight);
      const light = new THREE.DirectionalLight(0xffffff, 0.8);
      // light.castShadow = true; // true 이면 광원이 그림자를 생성합니다. 기본값은 false 입니다.
      light.position.set(-1000, 800, 5);
      this.scene.add(light);
    },

    loadObjects() {
      const loader = new GLTFLoader();
      loader.load('gltf/tanklorry.glb', (gltf) => {
        this.tankLorryGltf = gltf.scene;
        this.tankLorryGltf.scale.set(0.09, 0.09, 0.09); // Adjust scale if needed
        // tankLorry.position.set( 0, 0, 0)
        // this.tankLorryGltf.rotateY(Math.PI / 4);
        let cnt = 0;
        this.tankLorryGltf.traverse((child) => {
          if (child.isMesh) {
            cnt++;
            console.warn( "-------------gltf-child mesh exist ------------- mesh count = ", cnt ) ;
            child.material.color.set(0xFFFF00); // Set color to brown
            child.material.transparent = true;
            child.material.opacity = 0.3;
          }
        });
        // this.scene.add( tankLorry );

      }, undefined, (error) => {
        console.error('An error happened while loading the GLTF model:', error);
      });

      loader.load('gltf/f-35-base.glb', (gltf) => {
        this.f35Gltf = gltf.scene;
        this.f35Gltf.scale.set(9, 9, 9); // Adjust scale if needed
        this.f35Gltf.position.set(0, 600, 0)
        this.scene.add(this.f35Gltf);

        this.f35Gltf.traverse((child) => {
          if (child.isMesh) {
            child.material.color.set(0xffffff); // Set color to bright yellow
            child.material.transparent = true; // Set color to bright yellow
            child.material.opacity = 0.5; // Set color to bright yellow
          }
        });

      }, undefined, (error) => {
        console.error('An error happened while loading the Fighter 35 GLTF model:', error);
      });

      loader.load('gltf/f-16.glb', (gltf) => {
        let cnt = 0;
        const obj = gltf.scene;
        obj.scale.set(7, 7, 7); // Adjust scale if needed
        obj.position.set(0, 520, 0)
        obj.rotateY(Math.PI);
        this.scene.add(obj);
        this.f16Gltf = obj;

        obj.traverse((child) => {
          cnt++;
          console.warn( "-------------gltf-child mesh exist ------------- f16 -- mesh count = ", cnt ) ;
          if (child.isMesh) {
            child.material.color.set(0xffffff); // Set color to bright yellow
            child.material.transparent = true; // Set color to bright yellow
            child.material.opacity = 0.5; // Set color to bright yellow
          }
        });

      }, undefined, (error) => {
        console.error('An error happened while loading the Fighter 16 GLTF model:', error);
      });



    },

    async initLabels() {
      return new Promise((resolve, reject) => {
        const fontLoader = new FontLoader();
        // let font = this.font;
        // let drawLabel = this.drawLabel;
        fontLoader.load('/fonts/NanumGothic_Regular.json', (rs, err) => {
          this.font = rs;
          this.drawLabel('X', {x: 300, y: 0, z: 0, ax: 0, ay: 0, az: 0});
          this.drawLabel('Y', {x: 0, y: 300, z: 0, ax: 0, ay: 0, az: 0});
          this.drawLabel('Z', {x: 0, y: 0, z: 300, ax: 0, ay: 0, az: 0});
          console.log('################## font loaded #####################')
          if (err) reject(false);
          resolve(true);
        })
      })
    },

    /**
     * 탱크 초기화 - 객체들 모두 그린다.
     * @returns {Promise<void>}
     */
    async initTanks() {
      try {

        console.warn('\n\n\n\n\n\n\n\n\n------------------initTanks-------------------- tank count=', this.tanks.length);
        // const r = await apiCall('get', `/api/tank/lorry`);
        // this.tanks = r.result;
        for(const t of this.tanks) {

          if(!t.display) continue;
          const {tid} = t;

          this.fighterObjs[tid] = { mes: null, label: null };
          // if(t.location.coordinates[0]===0) {
          //   console.debug('location field not found draw skip---', t.name);
          //   continue;
          // }
          const pos = this.convertTankPosition(tid); // init-tanks

          if (t.position.x === 0 && t.position.z === 0) continue;

          // const {tankShape} = t;
          // pos['cx'] = (pos.x<0)? pos.x+pos.ax - 200 : pos.x + pos.ax + 200;
          // pos['cy'] = pos.y + pos.ay + 100;
          // pos['cz'] = (pos.z<0)? pos.z+pos.az - 250 : pos.z + pos.az + 250;
          // const updRs = await apiCall( 'put', `/api/tank/position-xyz/${t._id}`, pos);

          // const dataRs = await apiCall('get', `/api/inventory/latest/${tid}`);
          // const pk = dataRs.result;

          console.debug('[initTanks] drawTankLorry()------->', t.name, tid, pos, t.tankWidth, t.tankHeight, t.color);
          this.drawTankLorry(this.scene, t);

          const pk = this.pkMap[tid];
          if(pk) await this.initTankLorryVolume(tid, pk);
          // this.drawLabel( t.name, pos, 0x00FF00, 20, 5  );
          this.drawLabel(t.name, t.position, t.color, 10, 3, tid);
        }
        // this.$refs['excelGrid'].clearFilter();
      } catch (err) {
        console.error('[initTanks] --- error ---', err);
      }
    },

    drawTankLorryVolumeByPacket(tid, pk) {
      console.warn('\n\n\n\n\n-------------- draw-tank-lorry-volume-by-packet -------- tank volume percent --->', pk.tvp );
      const t = this.tankMap[tid];
      if(!t) return;

      if (!this.volObjs[tid]) {
        this.initTankLorryVolume(tid, pk);
      }

      const volObj = this.volObjs[tid]

      const pos = t.position?t.position:{ x:0, y:0, z:0, ax:0, ay:0, az:0};
      let px = pos.x + pos.ax;
      let py = pos.y + pos.ay;
      let pz = pos.z + pos.az;

      const maxW = 14;
      const maxH = 10;
      const maxD = 35;

      // let oilH = (pk.ohr - pk.whr) * this.hRatio;
      let oilH = (maxH * Number(pk.tvp)) / 100; // percent ex) (4 * 80.63) / 100
      let wtrH = pk.whr * this.hRatio;
      let wtrY = (wtrH * 0.5) + pos.y;
      let oilY = (maxH * -0.5) + (oilH * 0.5) + wtrH + py;

      console.debug(' draw-tank-lorry-volume-by-packet --- volObj =', volObj );

      if( volObj.mes.oil ) this.scene.remove(volObj.mes.oil);

      // const oilColor = getHexColor(this.oilColors[t.oilCode]);

      let geoOil = new THREE.BoxGeometry( maxW, oilH, maxD );
      let matOil = volObj.mat.oil;

      const tankR = volObj.tankR;
      const mesOil = new THREE.Mesh(geoOil, matOil);

      const targetObj = this.tankObjs[tid].mes; // this.tankObjs[tid].mes
      const labelObj = this.labelObjs[tid]; // this.tankObjs[tid].mes
      const fighterObj = this.fighterObjs[tid].mes;

      this.blinkObject(labelObj);

      // 충족률 80% 이상인 경우 빨간색으로
      switch(pk.sts.io){
        case 'R': // refueling = red
          this.coloringObject( targetObj, [255,0,0], 5, 7  );
          this.coloringObject( fighterObj, [0,100,0], 5, 7 );
          break;
        case 'D': // blue
          this.coloringObject( targetObj, [0,0,255], 5, 7  );
          this.coloringObject( fighterObj, [0,0,50], 5, 7 );
          break;
        case 'AR': // 급유인증 - 보라
          this.coloringObject( targetObj, [255,0,255], 5, 7  );
          break;
        case 'AD': // 배유인증 - 브라운
          this.coloringObject( targetObj, [255,255,0], 5, 7  );
          break;
        case 'F': // 배유인증 - 브라운
          this.coloringObject( targetObj, [1,1,0], 5, 7  );
          break;
        default:
          let green = parseInt(pk.tvp * 1.5);
          if( pk.tvp >= 90 ) {
            this.coloringObject( targetObj, [0,255,0], 5, 7  )
          }else if(pk.tvp < 90 && pk.tvp > 20) {
            this.coloringObject( targetObj, [5, green, 0], 3, 7);
          }else{
            this.coloringObject( targetObj, [1,1, 0], 3, 7);
          }
          break;
      }

      mesOil.position.set( px, oilY + 16, pz - 20 );
      mesOil.updateMatrix();

      this.scene.add(mesOil);

      this.volObjs[tid] = {
        geo: {
          oil: geoOil,
          wtr: null,
        },
        mat: {
          oil: matOil,
          wtr: null
        },
        mes: {
          oil: mesOil,
          wtr: null,
        },
        oilY,
        wtrY,
        oilH,
        wtrH,
        tankR
      };

      if( this.tank?.tid===tid){
        this.drawTankLorryVolOnLayer(t, pk);
      }

      gsap.to(mesOil.scale, {
        duration: 0.5,
        x: pk.isEvt ? 9 : 6, // 이벤트 있는경우 6배로 커짐
        z: pk.isEvt ? 9 : 6, // 이벤트 있는경우 6배로 커짐
        yoyo: true,
        repeat: pk.isEvt ? 9 : 5,
        ease: "sine.inOut",
        onComplete: () => {
          mesOil.scale.set(1, 1, 1);
        }
      });

      // 이벤트 있는경우
      if (pk.isEvt) {
        // const color = matOil.color;
        gsap.to(matOil.color, {
          duration: 0.5,
          r: 1,
          g: 0,
          b: 0,
          yoyo: true,
          repeat: 9,
          ease: "sine.inOut",
          onComplete: () => {
            // 애니메이션이 끝난 후 색상을 원래대로 복원
            // matOil.color = oilColor;
          }
        });
      }
    },

    /**
     * Blink Object Color
     * @param obj material
     * @param rgb
     * @param duration
     * @param repeat
     */
    coloringObject(obj, rgb=[1,1,1], duration=0.5, repeat=5){
      console.debug("-------------coloring object-------------", obj);
      if(!obj) return;
      // obj.material

      //
      // obj.visible = false;
      // setTimeout(()=>{
      //   obj.visible = true
      // }, 1000)

/*
      gsap.to(obj.color, {
        duration: duration,
        r: rgb[0],
        g: rgb[1],
        b: rgb[2],
        yoyo: true,
        repeat: repeat,
        ease: "sine.inOut",
        onComplete: ()=>{
        }
      });

      */

      obj.traverse((node)=>{
        if(node.isMesh){
          // let origin = node.material.color;
          gsap.to(node.material.color, {
            duration: duration,
            r: rgb[0],
            g: rgb[1],
            b: rgb[2],
            yoyo: true,
            repeat: repeat,
            ease: "sine.inOut",
            onComplete: ()=>{
            }
          });
        }
      })



    },

    handleTankLorry(pk){
      console.warn( '----------handle-tank-lorry-------- mid ===> ', pk.mid)
      const {tid} = pk;
      this.drawTankLorryVolumeByPacket(tid, pk);
      if(pk.fcd!=='04') this.setTankLorryPosition(tid, pk);

      if(pk.fcd==='02' || pk.fcd==='03' || pk.fcd==='04' ) {
        // 급유,배유,인증시
        this.drawFighter(pk.tid, pk.eqcd, pk.eqid);
      }else{
        this.removeFighter(pk.tid);
      }
    },

    /**
     * 탱크내 유량재고 그리기
     * @param tid
     * @param pk
     * @returns {Promise<void>}
     */
    async initTankLorryVolume(tid, pk) {
      console.warn('\n\n\n\n----------init-tank-lorry-volume ------------', tid);
      const t = this.tankMap[tid];
      const pos = t.position;

      let px = pos.x + pos.ax;
      let py = pos.y + pos.ay;
      let pz = pos.z + pos.az;

      const maxW = 14;
      const maxH = 10;
      const maxD = 35;

      // let oilH = (pk.ohr - pk.whr) * this.hRatio;
      let oilH = (maxH * Number(pk.tvp)) / 100; // percent ex) (4 * 80.63) / 100
      let wtrH = pk.whr * this.hRatio;
      let wtrY = (wtrH * 0.5) + py;
      let oilY = (maxH * -0.5) + (oilH * 0.5) + wtrH + py;

      console.warn(tid, t.name, "oilY =", oilY, "wtrY =", wtrY, '충족률 =', pk.tvp );

      const tankR = t.tankWidth * this.wRatio - 1;
      const oilColor = getHexColor(this.oilColors[t.oilCode]);
      // console.debug('init-tank-lorry-volume --- oilColor', this.oilColors[t.oilCode]);
      const geoOil = new THREE.BoxGeometry( maxW, oilH, maxD );
      const matOil = new THREE.MeshLambertMaterial({
        color: oilColor,
        side: THREE.DoubleSide,
        opacity: 0.5,
        transparent: true,
        depthTest: false
      });
      const mesOil = new THREE.Mesh(geoOil, matOil);
      mesOil.name = 'volume_'+t.tid;

      mesOil.position.set(px, oilY + 16, pz - 20);
      mesOil.updateMatrix();

      this.scene.add(mesOil);

      this.volObjs[tid] = {
        geo: {
          oil: geoOil,
          wtr: null,
        },
        mat: {
          oil: matOil,
          wtr: null
        },
        mes: {
          oil: mesOil,
          wtr: null,
        },
        oilY,
        wtrY,
        oilH,
        wtrH,
        tankR
      }
    },

    /**
     * 탱크로리_트럭 그리기 작업중
     * @param scene
     * @param t
     */
    drawTankLorry( scene, t) {
      console.warn('\n\n\n-------------drawTankLorry-------------------------');
      const pos = t.position;
      const {tid} = t;

      let px = pos.x + pos.ax;
      let py = pos.y + pos.ay;
      let pz = pos.z + pos.az;
      // this.tankLorryGltf.children[0].material.transparent = true;
      // this.tankLorryGltf.children[0].material.opacity = 0.5;
      // this.tankLorryGltf.updateMatrix();

      const tankLorry = this.tankLorryGltf.clone();


      tankLorry.name = 'tank_'+t.tid;


      let matCnt = 0;
      let matFirst = null;
      tankLorry.traverse( (node)=>{
        if (node.isMesh) {
          const newMaterial = node.material.clone();
          node.material = newMaterial;
          node.name = 'tank_'+t.tid;
          node.material.transparent = true; // Set color to bright yellow
          node.material.opacity = 0.5;
          if(matCnt===0) matFirst = node.material;

          this.tankObjs[tid] = { mes: tankLorry, mat: matFirst, angle: 0 }; // angle은 탱크로리만
        }
        matCnt++;
      });

      tankLorry.position.set( px, py, pz );
      tankLorry.updateMatrix();
      scene.add(tankLorry);
    },

    /**
     * 전투기 렌더링
     * @param tid
     * @param eqcd 기종
     * @param eqid 기기번호
     */
    drawFighter(tid, eqcd, eqid ){

      const tank = this.tankMap[tid];
      if(!tank) return;

      const p = tank.position;
      const fighterObj = this.fighterObjs[tid]

      let fighter = fighterObj.mes;
      let label = fighterObj.label;

      if( !fighter ) {
        if(eqcd==='01'){
          fighter = this.f35Gltf.clone();
          fighter.name = 'fighter_'+tid;
          fighter.traverse((node)=>{
            if (node.isMesh) {
              node.material = node.material.clone();
              node.name = 'fighter_'+tid;
              node.material.transparent = true; // Set color to bright yellow
              node.material.opacity = 0.6;
            }

            fighter.position.set( p.x, p.y+5, p.z+170 );
            fighter.rotateY(Math.PI);

            this.scene.add( fighter );
            fighterObj.mes = fighter;
          });
        }else{
          fighter = this.f16Gltf.clone();
          fighter.name = 'fighter_'+tid;
          fighter.traverse((node)=>{
            if (node.isMesh) {
              node.material = node.material.clone();
              node.name = 'fighter_'+tid;
              node.material.transparent = true; // Set color to bright yellow
              node.material.opacity = 0.6;
            }
            fighter.position.set( p.x, p.y+20, p.z+75 );
            fighter.rotateY(Math.PI);

            this.scene.add( fighter );
            fighterObj.mes = fighter;
          });
        }
      }

      if(!label){
        const labelStr = EqCodeMap[eqcd] + '_' + eqid;
        const labelPos = cloneVar(p);
        labelPos.z += 130;
        fighterObj.label = this.drawLabel( labelStr, labelPos, 0x00FF00, 6, 1  );
      }
    },

    removeFighter(tid){
      if( this.fighterObjs[tid]?.mes )  this.scene.remove(this.fighterObjs[tid].mes );
      if( this.fighterObjs[tid]?.label) this.scene.remove(this.fighterObjs[tid].label );
      this.fighterObjs[tid] = { mes: null, label: null };
    },

    /**
     * 탱크로리 추적 - 작업중
     * @param tid
     * @param coordinates
     */
    moveTankLorry(tid, coordinates) {
      const t = this.tankMap[tid];
      console.debug('moveTankLorry() --->', t.tid, coordinates);
      // const target = this.coordinatesToPosition( coordinates, 24 ); // 경도위도 좌표 변환
      t.location.coordinates = coordinates;
      this.tankMap[tid] = t;
      const target = this.convertTankPosition(tid); // 경도위도 좌표 변환
      console.debug(`moveTankLorry() --- target position ---> `, JSON.stringify(target));
      const object = this.tankObjs[tid].mes;

      /*
      const timeline = gsap.timeline({ repeat: -1, repeatDelay: 1 });
      timeline.to(object.position, {
        x: target.x,
        y: target.y,
        z: target.z,
        duration: 2,
        ease: "power1.inOut",
        onUpdate: () => {
          this.moveCamToTank(tid);
        }
      });
      */

      this.moveCamToTank(tid);

      const direction = new THREE.Vector3().subVectors(target, object.position);
      const angle = Math.atan2(direction.x, direction.z);
      gsap.to(object.rotation, {y: angle, duration: 1, ease: "power1.inOut"});

      gsap.to(object.position, {
        x: target.x, // 좌측
        y: target.y, // 높이
        z: target.z, // 아래
        duration: 5, // 애니메이션 시간 (초)
        onUpdate: () => {
          this.moveCamToTank(tid);
        }
      });
      // const direction = new THREE.Vector3().subVectors(target, object.position).normalize();
      // // const distance = object.position.distanceTo(target);
      // object.position.add(direction.multiplyScalar(speed));
    },

    /**
     * 탱크로리 추적 - 작업중
     * @param tid
     * @param pk
     * @param camTrace
     */
    async setTankLorryPosition(tid, pk, camTrace=false) {
      const t = this.tankMap[tid];
      if(!t) return;
      const target = this.convertPositionByPacket(pk); // 경도위도 좌표 변환
      const tankMesh = this.tankObjs[tid].mes;
      const volMesh = this.volObjs[tid].mes.oil;
      const labelMesh = this.labelObjs[tid];
      console.debug('---------- set  tank lorry position----- --->', t.tid, target );
      /*
      const timeline = gsap.timeline({ repeat: -1, repeatDelay: 1 });
      timeline.to(object.position, {
        x: target.x,
        y: target.y,
        z: target.z,
        duration: 2,
        ease: "power1.inOut",
        onUpdate: () => {
          this.moveCamToTank(tid);
        }
      });
      */
      // this.moveCamToTank(tid);

      // const tp = { x: target.x, y: target.y, z: target.z};
      const direction = new THREE.Vector3().subVectors( target, tankMesh.position);
      const angle = Math.atan2(direction.x, direction.z);
      this.tankObjs[tid].angle = angle;  // 헤드 각도 저장
      volMesh.visible = false;

      gsap.to(tankMesh.rotation, {y: angle, duration: 1, ease: "power1.inOut"});

      await sleep(800);

      gsap.to(tankMesh.position, {
        duration: 2, // 애니메이션 시간 (초)
        x: target.x, // 좌측
        y: target.y, // 높이
        z: target.z, // 아래
        onUpdate: () => {},
        onComplete: () => {
          gsap.to(
            tankMesh.rotation,
            {
              y: 0,
              duration: 1,
              ease: "power1.inOut",
              onComplete: () => {
                volMesh.visible = true;
              }
            }
          );
          // gsap.to(volMesh.rotation, {y: 0, duration: 1, ease: "power1.inOut"});
        }
      });

      gsap.to(volMesh.position, {
        duration: 2, // 애니메이션 시간 (초)
        x: target.x, // 좌측
        y: target.y+16, // 높이
        z: target.z-20, // 아래
        onUpdate: () => {
          // this.moveCamToTank(tid);
        }
      });

      gsap.to(labelMesh.position, {
        duration: 2, // 애니메이션 시간 (초)
        x: target.x, // 좌측
        z: target.z, // 아래
        onUpdate: () => {
          // this.moveCamToTank(tid);
        }
      });

      if(this.tank?.tid===tid) await this.traceTank(tid, pk);

      // gsap.to(labelMesh.rotation, {y: angle, duration: 1, ease: "power1.inOut"});
      // gsap.to(volMesh.rotation, {y: angle, duration: 1, ease: "power1.inOut"});

      /*
      gsap.to(object.scale, {
        duration: 1,
        x: 0.15, // 이벤트 있는경우 6배로 커짐
        y: 0.15, // 이벤트 있는경우 6배로 커짐
        z: 0.15, // 이벤트 있는경우 6배로 커짐
        yoyo: true,
        repeat: 10,
        ease: "sine.inOut",
        onComplete: () => {
          object.scale.set(0.09, 0.09, 0.09);
        }
      });
       */

      // const direction = new THREE.Vector3().subVectors(target, object.position).normalize();
      // // const distance = object.position.distanceTo(target);
      // object.position.add(direction.multiplyScalar(speed));
    },

    /**
     * 라벨출력
     * @param text 라벨
     * @param pos 위치
     * @param color 컬러
     * @param size 크기
     * @param depth 두께
     */
    drawLabel(text, pos, color = 0xFF0000, size = 30, depth = 10, tid = null) {
      let thr = 0;
      if (tid) thr = this.tankMap[tid].tankHeight * this.hRatio + pos.ay
      else thr = pos.y + pos.ay;

      let px = pos.x + pos.ax;
      let py = pos.ly ? thr + pos.ly : thr + 10
      let pz = pos.z + pos.az;

      const geometry = new TextGeometry(text, {
        font: this.font,
        size: size,
        depth: depth
      });

      const material = new THREE.MeshBasicMaterial({color: color});
      const label = new THREE.Mesh(geometry, material);

      if (pos.lyd) geometry.rotateY(pos.lyd);
      if( pos.z < 0 ) geometry.rotateY(Math.PI);
      // geometry.rotateY(Math.PI / 4.7);
      // geometry.rotateX(Math.PI / -4);

      label.position.set(px, py, pz);

      if(tid) {
        label.name = 'label_'+tid;
        this.labelObjs[tid] = label;
      }

      this.scene.add(label);

      return label;
    },

    onPageResizeHandler() {
      // console.debug( 'on -- pageResizeHandler --- resized---', window.innerWidth, window.innerHeight );
      this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    },

    convertTankPosition(tid, zoom = 25) {
      const t = this.tankMap[tid];
      const lat = t.location.coordinates[1];
      const lng = t.location.coordinates[0];

      console.warn( 'convertTankPosition() --------- t.location============>', t.location);

      if (!lat || !lng) {
        t.position = {x: 0, y: 0, z: 0, ax: 0, ay: 0, az: 0}
        return t.position
      }

      let centerPoint = Math.pow(2, zoom);
      let totalPixels = centerPoint * 2;
      let pixelsPerLngDegree = totalPixels / 360;
      let pixelsPerLngRadian = totalPixels / (2 * Math.PI);

      let sinY = Math.min(Math.max(Math.sin(lat * (Math.PI / 180)), -0.9999), 0.9999);

      let x = Math.round(centerPoint + lng * pixelsPerLngDegree);
      let z = Math.round(centerPoint - 0.5 * Math.log((1 + sinY) / (1 - sinY)) * pixelsPerLngRadian);

      let adjX = 57322397;
      let adjZ = 26186953;

      this.tankMap[tid].position.x = x - adjX;
      this.tankMap[tid].position.z = z - adjZ;
      // console.debug( 'convertTankPosition() coordinates --->', t.location.coordinates );
      // console.debug( 'convertTankPosition() --- calc ----- X, Z => ', x, z );
      // console.debug( `convertTankPosition() --- adjust ( ${x} - ${adjX} ,  ${z} - ${adjZ}) ====> ` , pos.x, pos.z );
      // console.debug( 'convertTankPosition() --- tankMap position --->', JSON.stringify(this.tankMap[tid].position) );
      // // console.debug( 'origin ----- X, Z => ', t?.position?.x, t?.position?.z );
      // console.debug( `convertTankPosition() ---> ${t.name} (${t.tid}) X,Z => ( ${x}, ${z} ) ==> ${JSON.stringify(pos)}`);

      return this.tankMap[tid].position;
    },

    convertPositionByPacket(pk, zoom = 25) {
      const {tid} = pk;
      this.tankMap[tid].location = pk.location;

      if( !pk.location ) return {x: 0, y: 0, z: 0, ax: 0, ay: 0, az: 0}

      const t = this.tankMap[tid];
      const lat = t.location?.coordinates[1];
      const lng = t.location?.coordinates[0];

      if (!lat || !lng) {
        t.position = {x: 0, y: 0, z: 0, ax: 0, ay: 0, az: 0}
        return t.position
      }

      let centerPoint = Math.pow(2, zoom);
      let totalPixels = centerPoint * 2;
      let pixelsPerLngDegree = totalPixels / 360;
      let pixelsPerLngRadian = totalPixels / (2 * Math.PI);

      let sinY = Math.min(Math.max(Math.sin(lat * (Math.PI / 180)), -0.9999), 0.9999);

      let x = Math.round(centerPoint + lng * pixelsPerLngDegree);
      let z = Math.round(centerPoint - 0.5 * Math.log((1 + sinY) / (1 - sinY)) * pixelsPerLngRadian);

      let adjX = 57322397;
      let adjZ = 26186953;

      t.position.x = x - adjX;
      t.position.z = z - adjZ;

      // console.debug( 'convertTankPosition() coordinates --->', t.location.coordinates );
      // console.debug( 'convertTankPosition() --- calc ----- X, Z => ', x, z );
      // console.debug( `convertTankPosition() --- adjust ( ${x} - ${adjX} ,  ${z} - ${adjZ}) ====> ` , pos.x, pos.z );
      // console.debug( 'convertTankPosition() --- tankMap position --->', JSON.stringify(this.tankMap[tid].position) );
      // // console.debug( 'origin ----- X, Z => ', t?.position?.x, t?.position?.z );
      // console.debug( `convertTankPosition() ---> ${t.name} (${t.tid}) X,Z => ( ${x}, ${z} ) ==> ${JSON.stringify(pos)}`);

      return t.position;
    },

    tankLorryAtgHandler(data) {
      const pk = data.payload;
      const {fcd} = pk;
      if( Number(fcd) > 1 ){
        console.info('-------------tankLorryAtgHandler-----\n', data);
        this.handleTankLorry(pk);
      }else{
        console.debug( 'skip handle tank-lorry', pk.fcd )
      }
    },

    setSocketConnection() {
      if (!this.$store.state.socket) {
        console.error("[MapMonitor] ############ socket not exists...", this.$store.state.socket);
        // console.warn("[MONITOR] ############ waiting register handler...");
      } else {
        console.warn("[MapMonitor] ############ socket object ----->", this.$store.state.socket);
        // console.warn("[MONITOR] ############ waiting register handler...");
      }

      setTimeout(() => {
        console.warn("[MapMonitor] ############ register atgDataHandler #########");
        this.socket = this.$store.state.socket;
        if (this.socket) {
          this.socket.removeListener('packet', this.tankLorryAtgHandler)
          this.socket.removeListener('disconnect');
          this.socket.removeListener('connect');
          this.socket.on('packet', this.tankLorryAtgHandler);
          this.socket.on('disconnect', () => {
            speech('ATG 서버와 통신이 유실 되었습니다.');
            modalWarn(this.$bvModal, 'ATG 서버와 통신이 유실 되었습니다.');
            // alert("[WARN] ATG 서버와 통신이 유실되었습니다. 모니터링을 종료합니다.")
            // window.close();
          });
          this.socket.on('connect', () => {
            speech('ATG 서버와 통신이 연결 되었습니다.');
            // modalSuccess(this.$bvModal, 'ATG 서버와 통신이 연결 되었습니다.');
          });
        }
        console.warn("[MapMonitor] ############ socket object ------> is connecting? ", this.socket.connected);
      }, 3000);
    },

    startDrag(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX - this.layerLeft;
      this.dragStartY = event.clientY - this.layerTop;
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },

    onDrag(event) {
      if (this.isDragging) {
        const parentRect = this.$refs.mapContainer.getBoundingClientRect();
        const newTop = event.clientY - this.dragStartY;
        const newLeft = event.clientX - this.dragStartX;

        // 상위 div 안에 있도록 위치를 제한
        this.layerLeft = Math.max(0, Math.min(newLeft, parentRect.width - 500));
        this.layerTop = Math.max(0, Math.min(newTop, parentRect.height - 660));
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },

    onMouseClick(event) {
      // 마우스 좌표를 정규화된 장치 좌표(-1 to +1)로 변환합니다.
      // this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      // this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      //
      let gapX = event.clientX - event.offsetX;
      let gapY = event.clientY - event.offsetY;

      this.mouse.x = ((event.clientX - gapX)/( this.container.clientWidth )) * 2 - 1;
      this.mouse.y = -((event.clientY - gapY)/( this.container.clientHeight )) * 2 + 1;

      // this.mouse.x = (event.clientX / this.container.offsetWidth) * 2 - 1;
      // this.mouse.y = -(event.clientY / this.container.offsetHeight) * 2 + 1;
      // 사용하여 클릭된 위치의 객체 감지
      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersects = this.raycaster.intersectObjects(this.scene.children);

      // 감지된 객체가 있는 경우 색상을 변경
      if (intersects.length > 0) {
        const obj = intersects[0].object;
        if( !obj.name ) return;

        const tid = obj.name.slice(-4);
        this.closeLayer();
        if(!this.tank) this.popupLayer( tid );

        // console.warn(  'obj =',obj );
        // console.warn(  'obj.name =',obj.name );
        // console.warn(  'obj.material =',obj.material );
        // obj.material.color?.set(0xff0000);
      }
    },

    onDocumentMouseMove( event ) {
      event.preventDefault();

      let gapX = event.clientX - event.offsetX;
      let gapY = event.clientY - event.offsetY;

      this.mouse.x = ((event.clientX - gapX)/( this.container.clientWidth )) * 2 - 1;
      this.mouse.y = -((event.clientY - gapY)/( this.container.clientHeight )) * 2 + 1;
    },
  },

  beforeDestroy() {
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    // this.renderer.domElement.removeEventListener('click', this.onDocumentMouseClick);
    window.removeEventListener('resize', this.onPageResizeHandler);
    window.removeEventListener('click', this.onMouseClick, false);

    this.renderer.renderLists.dispose();

    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    if (this.socket) {
      this.socket.removeListener('packet', this.tankLorryAtgHandler);
      this.socket.removeListener('disconnect');
      this.socket.removeListener('connect');
      // this.socket.off('atg-data', this.atgDataHandler );
    }
  },
}
</script>
